*{
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
}

:root{
    --white:#FFF;
    --black:#000;
    --gray-4a:#4A4A4A;
    --gray-4a-op5: rgba(74, 74, 74, 0.5);
    --gray-4a-op25: rgba(74, 74, 74, 0.25);
    --green-66:#669966;
    --grad-slider-l: linear-gradient(90deg, #8FD3F4 0%, #84FAB0 100%);
    --grad-slider-r:linear-gradient(90deg, #84FAB0 0%, #8FD3F4 100%);
}

.container{
    width: 75%;
    margin: auto;
    padding: 0;
    display: flex;
    /*background: red;*/
}

p,a,button,h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: inherit;
}
h1{font-size: 4em;}
h2{font-size: 3.4em;}
h3{font-size: 3em;}
h5{font-size: 1.4em;}
h6{font-size: 1.2em;}
p,a,p,li,button{font-size: 1em;}