.Preview{
    width: 100%;
    height: 500px;
    background: url("/public/images/Preview/background.svg");
    background-size: cover;
    color: var(--white);
}
.Preview .container{
    justify-content: flex-start;
    flex-wrap: wrap;
    /*background: rgba(8, 255, 0, 0.12);*/
}

.Preview .container img.logo{
    margin: 3em 0 5em;
}
.Preview .container h1{
    width: 100%;
    font-weight: 600;
}
.Preview .container h5{
    width: 100%;
    font-weight: 300;
}

.Preview .container a{
    margin-top: 5em;
    padding: .8em 3em;
    font-size: 1.3vmax;
    font-weight: 300;
    border-radius: .5em;
    border: 1px solid var(--white);
    transition: .3s;
}
.Preview .container a:hover{
    color: inherit;
    padding: .8em 4em;
}