@media screen and (min-width: 1750px){
    .Preview{
        height: 600px;
    }
}
@media screen and (max-width: 1000px){
    .Preview{
        height: 400px;
    }
}
@media screen and (max-width: 500px){
    .Preview .container img.logo{
        width: 100px;
        margin: 3em 0 4em;
    }
}