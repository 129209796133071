@media screen and (max-width: 1400px){
    h1{font-size: 3.2em;}
    h2{font-size: 3em;}
    h3{font-size: 2.6em;}
    h5{font-size: 1.2em;}
    h6{font-size: 1em;}
    p,a,p,li,button{font-size: .9em;}
}

@media screen and (max-width: 1000px){
    h1{font-size: 2.6em;}
    h2{font-size: 2.2em;}
    h3{font-size: 1.8em;}
    h5{font-size: 1em;}
    h6{font-size: .9em;}
    p,a,p,li,button{font-size: .8em;}

    .container{
        max-width: 95%;
        width: 95%;
    }
}

@media screen and (max-width: 700px){
    h1{font-size: 2em;}
    h2{font-size: 1.6em;}
    h3{font-size: 1.3em;}
    p.small{font-size: .7em;}
}

@media screen and (max-width: 400px){
    h1{font-size: 1.6em;}
    h2{font-size: 1.3em;}
    h3{font-size: 1.1em;}
    h5{font-size: .8em;}
    h6{font-size: .8em;}
}