.Form.container{
    margin: 0 auto 5em;
    padding: 5em 5em 2em;
    flex-wrap: wrap;
    border-radius: 1em;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    color: var(--gray-4a);
}
.Form.container h4{
    width: 100%;
    margin-bottom: .5em;
}
.Form.container h6{
    font-weight: 400;
}

/*content*/
.Form.container .content{
    padding: 3em 0 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.Form.container .content .block{
    width: 100%;
    margin: 1em 0;
}
.Form.container .content h6 strong{
    color: var(--green-66);
    padding-left: .2em;
}
/*input*/
.Form.container .content input{
    width: 100%;
    margin-top: .5em;
    padding: .5em 1em;
    border-radius: .5em;
    border: 1px solid var(--gray-4a-op5);
    outline: none;
    transition: .3s;
    color: var(--gray-4a);
    font-weight: 300;
    font-size: 1em;
}
.Form.container .content input:active,
.Form.container .content input:hover,
.Form.container .content input:focus{
    border: 1px solid var(--green-66);
}

/*button*/
.Form.container .content button{
    margin-top: 1em;
    border-radius: .8em;
    font-weight: 300;
    background: none;
    font-size: 1.2em;
    padding: .6em 3em;
    color: var(--gray-4a);
    border: 1px solid var(--green-66);
    transition: .3s;
}
.Form.container .content button:hover{
    color: var(--white);
    border: 1px solid rgba(255, 255, 255, 0);
    background: linear-gradient(90deg, #84FAB0 0%, #8FD3F4 100%);
}
.Form.container .content button:disabled{
    color: var(--gray-4a-op25);
    border: 1px solid var(--gray-4a-op25);
    cursor: not-allowed;
}
.Form.container .content button:disabled:hover{
    background: none;
}

/*block check*/
.Form.container .block.check{
    position: relative;
    width: 100%;
    margin: 2em auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Form.container .block.check p{
    margin: 0;
    padding: 0;
    font-weight: 300;
}
.Form.container .block.check input{
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    accent-color: var(--green-66);
    margin-right: .5em;
    margin-bottom: 10px;
}

