.Footer{
    background: #4A4A4A;
    padding: 1.5em 0;
}
.Footer .container{
    display: flex;
    justify-content: flex-end;
}

.Footer .container a:hover{
    transition: .3s;
    opacity: .5;
}

/*media*/
@media screen and (max-width: 600px){
    .Footer .container img{
        width: 100px;
    }
}